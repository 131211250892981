import React from "react";
import "../../../styles/Thankyou.css";
import tick from "../../../Images/thankyou/tick.webp";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function ThankyouPage() {
  const navigate = useNavigate();
  const [countdown, setCountdown] = React.useState(3);
  const device = useMediaQuery("(min-width:768px)");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //   React.useEffect(() => {
  //     const countdownInterval = setInterval(() => {
  //       setCountdown((prevCount) => prevCount - 1);
  //     }, 1000);

  //     if (countdown === 0) {
  //       clearInterval(countdownInterval);

  //       navigate("/");
  //     }

  //     return () => clearInterval(countdownInterval);
  //   }, [countdown]);

  return (
    <div className="thank_outer_div">
      <div className="thank_center">
        <img src={tick} alt="tick" className="thank_img_css" loading="lazy" />
      </div>
      <h1 className="thank_h1">Subscribed Successfully, Thank you</h1>
      <h2 className="thank_h2">
        Our technical representative will get in touch
        {device && <br />} with you shortly for the free modules
      </h2>
      {/* <h3 className="thank_redirect">Redirecting in ... {countdown}</h3> */}
      <button
        className="thank_button_redirect"
        onClick={() => {
          navigate("/");
        }}
      >
        Go Back
      </button>
    </div>
  );
}
