import React from "react";

const addItemToStore = (page, attribute, value) => {
  try {
    const data =
      JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORE)) || {};

    const updatedPage = {
      ...(data[page] || {}),
      [attribute]: value,
    };

    const updatedData = {
      ...data,
      [page]: updatedPage,
    };

    localStorage.setItem(
      process.env.REACT_APP_LOCAL_STORE,
      JSON.stringify(updatedData)
    );
  } catch (err) {
    console.log("Something happened");
    return null;
  }
};

export { addItemToStore };
