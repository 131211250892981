import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { caseStudies } from "./data";
import "../../styles/CaseStudies/Case.css";
import "../../styles/CaseStudies/List.css";
import "../../styles/CaseStudies/All.css";
import barcode from "../../Images/case_studies/barcode.webp";
import shared_img from "../../Images/case_studies/share.webp";
import shared_img2 from "../../Images/case_studies/share2.webp";
import read_img from "../../Images/case_studies/read2.webp";
import List2 from "./List";
import useMediaQuery from "@mui/material/useMediaQuery";
import ShareModal from "./Modal";

export default function CaseUI() {
  const urlMaps = {
    "how-netapp-benefited-from-sap-to-tally-erp-9-integration": 0,
    "journey-of-green-chef": 1,
    "how-tally-erp-import-model-is-useful": 2,
    "tally-erp-barcode-customization": 3,
    "how-kundan-electronics-benefited-from-tally-integration": 4,
  };

  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastPathValue = pathSegments[pathSegments.length - 1];
  const lastPathValueIndex = urlMaps[lastPathValue];

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const shareUrl = window.location.href;

  const device = useMediaQuery("(min-width:768px)");

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  React.useEffect(() => {
    console.log("called");
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, []);

  if (lastPathValueIndex == null || lastPathValueIndex == undefined)
    window.location.href = "/";

  let data = caseStudies[lastPathValueIndex];

  console.log(data);

  return (
    <div>
      <ShareModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        shareUrl={shareUrl}
      />
      <div className="case_stuies_case_outer">
        <div className="case_stuies_case_inner">
          {device && (
            <div className="case_stuies_case_inner_div1">
              <img
                className="case_stuies_case_inner_div1_img"
                alt="img"
                src={data.img}
                loading="lazy"
              />
            </div>
          )}
          <div className="case_stuies_case_inner_div2">
            <h1 className="case_stuies_case_inner_div2_h1">{data.name}</h1>

            <div className="case_list_div_outer_inner_list_ind_div2_div1">
              <div className="case_list_div_outer_inner_list_ind_div2_div1_div1">
                <img
                  className="case_list_div_outer_inner_list_ind_div2_div1_div1_img"
                  alt="img"
                  src={read_img}
                  loading="lazy"
                />
                <span className="case_list_div_outer_inner_list_ind_div2_div1_div1_span">
                  {data.time}
                </span>
              </div>
              <div
                className="case_list_div_outer_inner_list_ind_div2_div1_div2_special"
                style={{ cursor: "pointer" }}
                onClick={openModal}
              >
                <img
                  className="case_list_div_outer_inner_list_ind_div2_div1_div1_img2"
                  alt="img"
                  src={shared_img}
                  loading="lazy"
                />
                <span className="case_list_div_outer_inner_list_ind_div2_div1_div1_span2">
                  Share
                </span>
              </div>
            </div>
            {!device && (
              <div className="case_stuies_case_inner_div1">
                <img
                  className="case_stuies_case_inner_div1_img"
                  alt="img"
                  src={data.img}
                  style={{ marginTop: "15px" }}
                  loading="lazy"
                />
              </div>
            )}
            <div className="case_stuies_case_inner_div2_div2">
              {data &&
                data.content &&
                data.content.map((item, index) => {
                  return (
                    <p className="case_stuies_case_inner_div2_div2_p">{item}</p>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: device ? "-20px" : "-50px" }}>
        <List2 />
      </div>
    </div>
  );
}
