import supabase from "../../Supabase";
import emailjs from "@emailjs/browser";

export const contactUs = async (fname, lname, email, requi) => {
  try {
    const { data: newRecord, error } = await supabase
      .from("contact_us")
      .insert([
        {
          first_name: fname,
          last_name: lname,
          email: email,
          requirement: requi,
        },
      ]);

    try {
      if (window.Email) {
        window.Email.send({
          SecureToken: process.env.REACT_APP_SECURE_TOKEN,
          To: "growth@amsantechnology.com",
          From: "growth@amsantechnology.com",
          Subject: "New Contact ",
          Body: `<p>Hello Santosh,</p>
<p>You got a new contact :</p>
<p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">
First Name : ${fname}<br>
Last Name : ${lname}<br>
Email : ${email}<br>
Requirement : ${requi}<br>
</p>
<p>
  Best wishes,<br>Team Amsan
</p>`,
        }).then((message) => {
          console.log("Succedss");
        });
      }
    } catch (err) {
      console.log(err);
    }

    if (error) {
      console.error("Error pushing data to Supabase:", error);
      return false;
    }

    console.log("Data pushed to Supabase:", newRecord);
    return true;
  } catch (error) {
    console.error("Error:", error.message);
    return false;
  }
};
