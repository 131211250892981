import { useCallback } from "react";

const useScrollLock = () => {
  const lockScroll = useCallback(() => {
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrollBarCompensation}px`;
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = `0`;
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};

export default useScrollLock;
