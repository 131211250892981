import React from "react";
import "../../styles/CaseStudies/List.css";
import "../../styles/CaseStudies/All.css";
import barcode from "../../Images/case_studies/barcode.webp";
import shared_img from "../../Images/case_studies/share.webp";
import shared_img2 from "../../Images/case_studies/share2.webp";
import read_img from "../../Images/case_studies/read2.webp";
import { caseStudies as caseStudiesDataTemp } from "./data";
import { useNavigate, useLocation } from "react-router-dom";

export default function AllUI() {
  const [selectedVal, setSelectedVal] = React.useState("al");
  const navigate = useNavigate();
  const urlMaps = {
    0: "how-netapp-benefited-from-sap-to-tally-erp-9-integration",
    1: "journey-of-green-chef",
    2: "how-tally-erp-import-model-is-useful",
    3: "tally-erp-barcode-customization",
    4: "how-kundan-electronics-benefited-from-tally-integration",
  };

  const [caseStudiesData, setCaseStudiesData] = React.useState([]);

  React.useEffect(() => {
    setCaseStudiesData(caseStudiesDataTemp);
  }, []);

  // const caseStudiesData = [
  //   {
  //     name: "Tally Barcode Customizer",
  //     time: "8 min read",
  //     shareLink: "",
  //     image: "",
  //   },
  //   {
  //     name: "How Tally ERP Import Model is Useful ???",
  //     time: "8 min read",
  //     shareLink: "",
  //     image: "",
  //   },
  // ];

  return (
    <div>
      <div className="case_list_div_outer case_all_outer">
        <div className="case_list_div_inner">
          <div className="case_list_div_inner2">
            <div className="case_list_div_inner3">
              <h1 className="case_list_div_inner2_h1_2">All Case studies</h1>
              <div className="case_list_div_inner4_2">
                <div className="case_all_sortby">
                  <span className="case_all_sortby_span">Sort By</span>
                  <select
                    id="countries"
                    class="bg-white border border-gray-300 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white transition-colors duration-300 focus:border-black"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setSelectedVal(e.target.value);
                      setCaseStudiesData(caseStudiesData.reverse());
                    }}
                  >
                    <option
                      class="bg-white"
                      selected={selectedVal == "al"}
                      value="al"
                      style={{
                        fontWeight: selectedVal == "al" ? 600 : 400,
                      }}
                    >
                      All
                    </option>
                    <option
                      class="bg-white"
                      value="mr"
                      selected={selectedVal == "mr"}
                      style={{
                        fontWeight: selectedVal == "mr" ? 600 : 400,
                      }}
                    >
                      Most Read
                    </option>
                    <option
                      class="bg-white"
                      value="la"
                      selected={selectedVal == "la"}
                      style={{
                        fontWeight: selectedVal == "la" ? 600 : 400,
                      }}
                    >
                      Latest
                    </option>
                    <option
                      class="bg-white"
                      value="ol"
                      selected={selectedVal == "ol"}
                      style={{
                        fontWeight: selectedVal == "ol" ? 600 : 400,
                      }}
                    >
                      Oldest
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="case_list_div_outer case_all_outer">
        <div className="case_list_div_inner">
          <div className="case_list_div_inner2_2 case_all_inner">
            {caseStudiesData.map((item, index) => {
              return (
                <div
                  className="case_list_div_outer_outer_list_ind"
                  onClick={() => {
                    navigate("/case-studies/" + urlMaps[index]);
                    window.location.reload();
                  }}
                >
                  <div className="case_list_div_outer_inner_list_ind">
                    <div className="case_list_div_outer_inner_list_ind_div2_div1_div2_new_mobile_share">
                      <img
                        className="case_list_div_outer_inner_list_ind_div2_div1_div1_img2"
                        alt="img"
                        src={shared_img}
                        loading="lazy"
                      />
                    </div>
                    <div className="case_list_div_outer_inner_list_ind_div1">
                      <img
                        alt="alt"
                        src={item.img}
                        className="case_list_div_outer_inner_list_ind_div1_img"
                        loading="lazy"
                      />
                    </div>
                    <div className="case_list_div_outer_inner_list_ind_div2">
                      <h1 className="case_list_div_outer_inner_list_ind_div2_h1">
                        {item.name}
                      </h1>
                      <div className="case_list_div_outer_inner_list_ind_div2_div1">
                        <div className="case_list_div_outer_inner_list_ind_div2_div1_div1">
                          <img
                            className="case_list_div_outer_inner_list_ind_div2_div1_div1_img"
                            alt="img"
                            src={read_img}
                            loading="lazy"
                          />
                          <span className="case_list_div_outer_inner_list_ind_div2_div1_div1_span">
                            {item.time}
                          </span>
                        </div>
                        <div className="case_list_div_outer_inner_list_ind_div2_div1_div2">
                          <img
                            className="case_list_div_outer_inner_list_ind_div2_div1_div1_img2"
                            alt="img"
                            src={shared_img}
                            loading="lazy"
                          />
                          <span className="case_list_div_outer_inner_list_ind_div2_div1_div1_span2">
                            Share
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="case_list_div_outer_outer_list_ind_divider"></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
