import React from "react";
import "../../styles/CaseStudies/CaseStudies.css";
import ListUI from "./List";
import AllUI from "./All";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import supabase from "../../Supabase";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";

export default function CaseStudiesUI() {
  const device = useMediaQuery("(min-width:768px)");
  const [userData, setUserData] = React.useState(null);
  const navigate = useNavigate();
  const [trigger, setTrigger] = React.useState("");

  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    const checkAuthStatusAndInitSignIn = async () => {
      // Check if there's an authenticated user

      const {
        data: { user },
      } = await supabase.auth.getUser();

      console.log(user);
      if (!user) {
        // User is not authenticated, proceed with Google one-tap sign-in initialization

        initGoogleSignIn();
      } else {
        // User is already authenticated, you can handle this case if needed
        console.log("User is already authenticated:", user);

        setUserData(user);
      }
    };

    const initGoogleSignIn = () => {
      // Ensure that the Google Identity Services library is loaded
      if (window.google && window.google.accounts) {
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: loginWithGoogle,
          origin: window.location.origin,
        });

        // Render the Google one-tap sign-in button
        window.google.accounts.id.renderButton(
          document.getElementById("buttonDiv"),
          { theme: "outline", size: "large" } // Customize attributes as needed
        );

        // Prompt for one-tap login
        window.google.accounts.id.prompt((notification) => {
          console.log("One-tap notification:", notification);
        });
      } else {
        console.error("Google Identity Services library not found.");
      }
    };

    const loginWithGoogle = async ({ credential }) => {
      try {
        // Use `jwt-decode` package to get the email address from the credential jwt.
        const decoded = jwtDecode(credential);

        // Use the email for the second pass with Supabase's signInWithOAuth
        await supabase.auth.signInWithOAuth({
          provider: "google",
          options: {
            queryParams: { login_hint: decoded.email },
            redirectTo: "https://www.amsantechnology.in/amsan-admin",
          },
        });
      } catch (error) {
        console.error("Google sign-in error:", error.message);
      }
    };

    // Check authentication status and initialize Google one-tap sign-in
    checkAuthStatusAndInitSignIn();
  }, []);

  React.useEffect(() => {
    console.log("called");
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="case_outer_div">
      {/* <ListUI /> */}
      <div style={{ height: device ? "50px" : "40px" }}></div>
      <AllUI />
    </div>
  );
}
