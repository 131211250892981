import React from "react";
import img_des_1 from "../../Images/about_us/desktop/1.webp";
import img_des_2 from "../../Images/about_us/desktop/2.webp";
import img_des_3 from "../../Images/about_us/desktop/3.webp";
import img_des_4 from "../../Images/about_us/desktop/4.webp";
import img_des_5 from "../../Images/about_us/desktop/5.webp";
import img_des_6 from "../../Images/about_us/desktop/6.webp";

import img_mob_1 from "../../Images/about_us/mobile/1.webp";
import img_mob_2 from "../../Images/about_us/mobile/2.webp";
import img_mob_3 from "../../Images/about_us/mobile/3.webp";
import img_mob_4 from "../../Images/about_us/mobile/4.webp";
import img_mob_5 from "../../Images/about_us/mobile/5.webp";
import img_mob_6 from "../../Images/about_us/mobile/6.webp";
import img_mob_7 from "../../Images/about_us/mobile/7.webp";
import img_mob_8 from "../../Images/about_us/mobile/8.webp";
import img_mob_9 from "../../Images/about_us/mobile/9.webp";
import img_mob_10 from "../../Images/about_us/mobile/10.webp";
import img_mob_11 from "../../Images/about_us/mobile/11.webp";
import img_mob_12 from "../../Images/about_us/mobile/12.webp";
import img_mob_13 from "../../Images/about_us/mobile/13.webp";

import { setShowModal } from "../../Redux/Cart/CartStore";
import "../../styles/AboutUs/about.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";

export default function AboutUs() {
  const device = useMediaQuery("(min-width:768px)");
  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log("called");
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: "#F9F9FB" }}>
      {device ? (
        <div>
          <div className="about_us_desk_div1">
            <img
              src={img_des_1}
              alt="img"
              className="about_us_desk_1"
              loading="lazy"
            />
          </div>
          <div className="about_us_desk_div2">
            <img
              src={img_des_2}
              alt="img"
              className="about_us_desk_2"
              loading="lazy"
            />
          </div>
          <div className="about_us_desk_div3">
            <img
              src={img_des_3}
              alt="img"
              className="about_us_desk_3"
              loading="lazy"
            />
          </div>
          <div className="about_us_desk_div4">
            <img
              src={img_des_4}
              alt="img"
              className="about_us_desk_4"
              loading="lazy"
            />
          </div>
          <div className="about_us_desk_div5">
            <img
              src={img_des_5}
              alt="img"
              className="about_us_desk_5"
              loading="lazy"
            />
          </div>
          <div
            className="about_us_desk_div6"
            onClick={() => {
              dispatch(setShowModal(true));
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              src={img_des_6}
              alt="img"
              className="about_us_desk_6"
              loading="lazy"
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="about_us_mob_div1">
            <img
              src={img_mob_1}
              alt="img"
              className="about_us_mob_1"
              loading="lazy"
            />
          </div>
          <div className="about_us_mob_div2">
            <img
              src={img_mob_2}
              alt="img"
              className="about_us_mob_2"
              loading="lazy"
            />
          </div>
          <div className="about_us_mob_div3">
            <img
              src={img_mob_3}
              alt="img"
              className="about_us_mob_3"
              loading="lazy"
            />
          </div>
          <div className="about_us_mob_div4">
            <img
              src={img_mob_4}
              alt="img"
              className="about_us_mob_4"
              loading="lazy"
            />
          </div>
          <div className="about_us_mob_div5">
            <img
              src={img_mob_5}
              alt="img"
              className="about_us_mob_5"
              loading="lazy"
            />
          </div>
          <div className="about_us_mob_div6">
            <img
              src={img_mob_6}
              alt="img"
              className="about_us_mob_6"
              loading="lazy"
            />
          </div>
          <div className="about_us_mob_div7">
            <img
              src={img_mob_7}
              alt="img"
              className="about_us_mob_7"
              loading="lazy"
            />
          </div>
          <div className="about_us_mob_div8">
            <img
              src={img_mob_8}
              alt="img"
              className="about_us_mob_8"
              loading="lazy"
            />
          </div>
          <div className="about_us_mob_div9">
            <img
              src={img_mob_9}
              alt="img"
              className="about_us_mob_9"
              loading="lazy"
            />
          </div>
          <div className="about_us_mob_div10">
            <img
              src={img_mob_10}
              alt="img"
              className="about_us_mob_10"
              loading="lazy"
            />
          </div>
          <div className="about_us_mob_div11">
            <img
              src={img_mob_11}
              alt="img"
              className="about_us_mob_11"
              loading="lazy"
            />
          </div>
          <div className="about_us_mob_div12">
            <img
              src={img_mob_12}
              alt="img"
              className="about_us_mob_12"
              loading="lazy"
            />
          </div>
          <div
            className="about_us_mob_div13"
            onClick={() => {
              dispatch(setShowModal(true));
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              src={img_mob_13}
              alt="img"
              className="about_us_mob_13"
              loading="lazy"
            />
          </div>
        </div>
      )}
    </div>
  );
}
