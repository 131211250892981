import React from "react";
import updates_bg from "../../Images/updates/updates.webp";
import see_whats_new from "../../Images/updates/see_whats_new.webp";
import new_bg from "../../Images/updates/new_bg.webp";
import below_line from "../../Images/updates/below_line.webp";
import see_bg from "../../Images/updates/see_bg.webp";
import see_sub1 from "../../Images/updates/see_sub1.webp";
import see_sub2 from "../../Images/updates/see_sub2.webp";
import see_sub3 from "../../Images/updates/see_sub3.webp";
import "../../styles/Updates/Updates.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import beta_img from "../../Images/updates/beta.webp";

export default function UpdatesUI() {
  const updates_data = [
    {
      head: "Printing Previous and Current Balances in Invoice ",
      info: "Invoice printing is all the more result-oriented now, as you can print an invoice for a party with the Previous and Current Balances, as applicable at the time of printing.",
    },
    {
      head: "Exporting GSTR-3B for a Quarter in a Single JSON File for QRMP Dealers  ",
      info: "QRMP dealers can now export GSTR-3B for a quarter in a single JSON file and then upload on the GST portal to file GSTR-3B.",
    },
    {
      head: "Party GSTIN/UIN in Input Tax Credit Available Section of GSTR-3B  ",
      info: "When you drill down from the Input Tax Credit Available section and view vouchers Party-wise, you will also get to see the party GSTIN/UIN.",
    },
    {
      head: "Explore TallyEdge from Within TallyPrime   ",
      info: "Exploring TallyEdge – your go-to account aggregator – is all the easier now, as you can head on to explore it through the Exchange menu in TallyPrime.",
    },
    {
      head: "Explore TallyPrime Powered by AWS from TallyPrime   ",
      info: "Exploring TallyPrime Powered by AWS is all the easier now, as you can know about it from the Help menu in TallyPrime.",
    },
    {
      head: "Exporting TDS and TCS Returns As Per Latest FVU Tool 8.2   ",
      info: "",
      override: true,
      html: (
        <div>
          <span>
            You can now export the following return reports according to the
            latest FVU Tool 8.2:{" "}
          </span>
          <ul
            style={{
              listStyleType: "disc",
              marginLeft: "20px",
              marginTop: "10px",
            }}
          >
            <li>TDS Form-24Q </li>
            <li>TDS Form-26Q </li>
            <li>Form-27Q TCS </li>
            <li>Form-27EQ</li>
          </ul>
        </div>
      ),
    },
    {
      head: "Count of Cancelled    ",
      info: "The count of cancelled vouchers of all the months will be included in the GSTR-1 Document Summary, when you cancel one or more Sales Invoices.",
    },
    {
      head: "GST Ledgers in RCM and Transactions with Unregistered Dealers (URD)     ",
      info: "You can now add GST ledgers in the RCM purchase vouchers and purchases from the URDs, and rest assured these vouchers will be included in the returns.",
    },
    {
      head: "Updation of GST Amount After Overriding GST Rate)     ",
      info: "Updation of GST Amount in vouchers is a surety, even if you override the GST Rate. Even if the option Use common ledger for item allocation configuration is set to No, you will now be able to seamlessly override the GST Rate, and the GST Amount will get updated.",
    },
    {
      head: "Printing of Company      ",
      info: "",
      override: true,
      html: (
        <div>
          <span>
            The GSTIN/UIN will now get printed in Job Work Out Orders.
          </span>
          <ul
            style={{
              listStyleType: "disc",
              marginLeft: "20px",
              marginTop: "10px",
            }}
          >
            <li>
              In the case of multiple GST Registrations, the GST Registration
              (chosen during voucher creation) and the State Code will get
              printed.{" "}
            </li>
            <li>
              In the case of single GST Registration, you will be able to enter
              the GSTIN/UIN and State Code details using More Details, and print
              the same.
            </li>
          </ul>
        </div>
      ),
    },
    {
      head: "Total Value Field in      ",
      info: "The experience of exporting an MS Excel or CSV of GSTR-1 HSN Summary is a lot better now. The Total Value field will now be displayed with the total GST amount, even when you export GSTR-1 HSN Summary as an MS Excel or CSV file.",
    },
    {
      head: "Taxable and Tax Amount Doubled for Certain Transactions in Nature View of GSTR-3B       ",
      info: "Previously, when a transaction was included in two different sections, the Taxable and Tax Amount got doubled. Viewing GSTR-3B in Nature View is a lot smoother now, as you will be able to view the correct Taxable and Tax Amount",
    },
    {
      head: "Vouchers Recorded for Import of Services in GSTR-3B        ",
      info: "The vouchers recorded for Import of Services that are exempt from taxes will now get included only in the 4 A. Input Tax Credit Available section and will not be a part of the 3.1d. Inward Supplies (applicable for Reverse Charge) section.",
    },
    {
      head: "Seamless Export of GSTR-1 When Data was Imported from Another Software      ",
      info: "The export of GSTR-1 from TallyPrime is now seamless, even when the data was imported from another software.",
    },
    {
      head: "Purchase Vouchers with the Same Bill of Entry No.       ",
      info: "You can now record more than one purchase vouchers with the same Bill of Entry No., and the transactions will get included in returns.",
    },
    {
      head: "Voucher Upload on GST Portal Wh        ",
      info: "You can now upload vouchers with stock items where the Unit of Measurement is not applicable with the following facilities: The voucher created for goods will display the UoM as OTH. The voucher created for services will display the UoM as NA.",
    },
    {
      head: "Seamless Creation of Vouchers with GST Data         ",
      info: "Voucher creation with GST-related information is a lot smoother now, as you will not face any challenges or errors during voucher creation.",
    },
    {
      head: "HSN/SAC, Description, and Party GSTIN in Excel File Exported Through ODBC          ",
      info: "HSN/SAC, Description, and Party GSTIN were not part of the MS Excel file when exported through ODBC. This issue is resolved.",
    },
    {
      head: "Smooth Running of TallyPrime Irrespective of Length of HSN/SAC          ",
      info: "TallyPrime will now continue to run smoothly when you open GSTR-1 and GSTR-3B, even when the length of HSN/SAC in transactions exceeds 1024 characters.",
    },
    {
      head: "Seamless Migration to TallyPrime Release 4.0          ",
      info: "",
      override: true,
      html: (
        <div>
          <span>
            If you are using TallyPrime Release 2.1 or earlier, you will now be
            able to seamlessly migrate your Company data to TallyPrime without
            memory-related errors, such as those occurring due to the following
            reasons:
          </span>
          <ul
            style={{
              listStyleType: "disc",
              marginLeft: "20px",
              marginTop: "10px",
            }}
          >
            <li>
              The size of the Company data or the number of vouchers is large.
            </li>
            <li>
              The vouchers contain stock items or services with slab-based
              rates, where the Include Expense for slab calculation
              configuration is enabled.
            </li>
          </ul>
          <span>
            However, if you are working on TallyPrime Release 3.0 or 3.0.1, then
            you will not need to perform the process of migration. You can just
            load your Company data in TallyPrime Release 4.0 and continue your
            work.
          </span>
        </div>
      ),
    },
    {
      head: "Partial Payments to Payment Requests Through UPI           ",
      info: "Your buyers will now be able to alter the Amount and make partial payments, when you send the Payment Request through UPI.",
    },
    {
      head: "QR Codes for Payment Request for Transactions with Cash Ledger           ",
      info: "The QR Code for Payment Request will now get generated even if the transaction is recorded with the Cash ledger.",
    },
    {
      head: "Printing QR Code for Payment Request in Simple Invoice Format When GST is Disabled           ",
      info: "You will now be able to print QR Code for Payment Request in the Simple Invoice format, even when GST is disabled under F11 (Company Features).",
    },
    {
      head: "Generating Payment Link Prior to Printing            ",
      info: "When you create a voucher, TallyPrime will now prompt you to generate the payment link prior to printing. This will happen when Generate payment link/QR Code after saving voucher and Print voucher after saving are enabled in the Voucher Type master. Generating the payment link prior to printing ensures that the print has both – the payment link and QR Code – for Payment Request.",
    },
    {
      head: "Seamless e-Invoice Generation             ",
      info: "",
      override: true,
      html: (
        <div>
          <span>
            E-Invoice generation is a lot more seamless now, as you can now
            successfully generate an e-Invoice in the following scenarios:
          </span>
          <ul
            style={{
              listStyleType: "disc",
              marginLeft: "20px",
              marginTop: "10px",
            }}
          >
            <li>The invoice contains a government entity as a consignee.</li>
            <li>An intrastate transaction is not applicable for e-Way Bill.</li>
          </ul>
          <span>
            Moreover, you will not face the The ValDtls field is required error
            when you generate an e-Invoice for the sale of goods or services.
          </span>
        </div>
      ),
    },
    {
      head: "QR Code in Sales Invoice for Multi-Invoice Printing              ",
      info: "Multi-invoice printing with QR Code is flawless now. When you print multiple Sales Invoices, all the invoices will now have the QR Code.",
    },
    {
      head: "Printing e-Way Bill with e-Invoice               ",
      info: "When you had enabled the printing e-Way Bill with e-Invoice (under F12 in the Invoice configuration), the configuration applied only to one invoice. The configuration will now be applied to all the invoices, even if you shut TallyPrime or load a different Company.",
    },
    {
      head: "Selection of Order No. in Delivery Notes While Working on Client Computer              ",
      info: "In the client computer of a multi-user environment, the time taken to select the Order No. of the party while creating a Delivery Note was more than expected. With the enhanced performance, you will be able to select the Order No. in a jiffy",
    },
    {
      head: "Title of Sales Invoices After Downloading from Browser               ",
      info: "When you download a Sales Invoice from a browser, the title of the invoice changes from Tax Invoice to Bill of Supply. The title of the Sales Invoice will now be retained as Tax Invoice, when you download it from a browser.",
    },
    {
      head: "Marginal Tax Relief for Employees with Taxable Income Between Rs. 7,00,000 and Rs. 7,27,777                ",
      info: "Marginal Tax Relief is now applicable only to the employees who have opted for the New Tax Regime with taxable income between Rs. 7,00,000 and Rs. 7,27,777.",
    },
    {
      head: "GCC VAT Details in Invoice with Ledger Created Under a Sub-Group                 ",
      info: "When you used a ledger created under a sub-group such as Fixed Assets, the GCC VAT details were not retained. You will now have a smoother experience in creating invoice with the GCC VAT details, as irrespective of the ledger and its sub-group, the GCC VAT details will be retained.",
    },
  ];

  const device = useMediaQuery("(min-width:768px)");

  React.useEffect(() => {
    console.log("called");
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="updates_super_outer">
      <div className="updates_outer_div">
        <div className="updates_inner_div">
          <img alt="Alt" src={updates_bg} className="updates_inner_div_img" />
          <h1 className="updates_h1_1">We have an update in</h1>
          <h1 className="updates_h1_2">
            Tally Prime and Tally Prime Edit Log Release 4.0
          </h1>
          <p className="updates_p_1">
            TallyPrime and TallyPrime Edit Log Release 4.0 introduce several
            exciting features and improvements, including the ability to share
            business documents via WhatsApp, simplified data import from MS
            Excel, a graphical dashboard for business insights, and enhancements
            in modules like GST and Payment Request. Additionally, you can now
            print previous and current balances on invoices, making the
            TallyPrime experience more fruitful. Release notes are also
            available in various regional languages for user in Hindi, Gujarati,
            Bengali, Telugu, Malayalam, Kannada, and Tamil
          </p>
        </div>
      </div>
      {device ? (
        <img
          alt="see whats new"
          src={see_whats_new}
          className="updates_see_whats_new"
          loading="lazy"
        />
      ) : (
        <div
          className="updates_see_whats_new_mobile"
          style={{
            backgroundImage: `url(${see_bg})`,
          }}
        >
          <div className="updates_see_whats_new_mobile_inner">
            <div className="updates_see_whats_new_mobile_inner_div2">
              <img
                alt="see whats new"
                src={see_sub1}
                className="updates_see_whats_new_2"
                loading="lazy"
              />
              <img
                alt="see whats new"
                src={see_sub2}
                className="updates_see_whats_new_2"
                loading="lazy"
              />
              <img
                alt="see whats new"
                src={see_sub3}
                className="updates_see_whats_new_2"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      )}
      <div className="updates_outer_div_2">
        <div className="updates_inner_div">
          <h1 className="updates_h1_1">Product improvements in</h1>
          <h1 className="updates_h1_2">
            Tally Prime and Tally Prime Edit Log Release 4.0
          </h1>

          <div className="updates_inner_list_updates">
            {updates_data.map((item, index) => {
              return (
                <div
                  key={item}
                  className="updates_inner_list_updates_list_item shadow"
                >
                  <div className="updates_inner_list_updates_list_item_inner">
                    <h1 className="updates_inner_list_updates_list_item_inner_h1">
                      {item.head}
                    </h1>
                    <p className="updates_inner_list_updates_list_item_inner_h1_2">
                      {item.override ? item.html : item.info}
                    </p>
                  </div>
                  <div className="updates_inner_list_updates_list_item_inner_below_line"></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="updates_outer_div_div2">
        <div className="updates_inner_div">
          {/* <img alt="Alt" src={updates_bg} className="updates_inner_div_img" /> */}
          <h1 className="updates_h1_2">TallyPrime 4.0</h1>
          <p className="updates_p_1">
            Experience the Future: TallyPrime 4.0 - Elevating Business
            Management with Unmatched Features!
            <ul
              style={{
                marginTop: "10px",
                listStyleType: "disc",
                marginLeft: device ? "30px" : "20px",
                lineHeight: device ? "1.5em" : "2em",
              }}
            >
              <li style={{ fontSize: device ? "16px" : "15px" }}>
                <i> TallyPrime with WhatsApp for Business</i>
              </li>
              <li style={{ fontSize: device ? "16px" : "15px" }}>
                {" "}
                <i>Import your data to Tally directly from Excel</i>
              </li>
              <li style={{ fontSize: device ? "16px" : "15px" }}>
                {" "}
                <i>All new Graphical Dashboard for data visualisation</i>
              </li>
              <li style={{ fontSize: device ? "16px" : "15px" }}>
                {" "}
                <i>Get e-payments directly from Tally</i>
              </li>
            </ul>
          </p>
          <div className="updates_new_beta">
            {/* {!device && (
              <div style={{ width: "62px", marginBottom: "10px" }}>
                <div className="updates_new_beta_div1_1">NEW</div>
              </div>
            )} */}
            <div className="updates_new_beta_div1">
              {device && <div className="updates_new_beta_div1_1">NEW</div>}
              <a
                href="https://tallymirror.tallysolutions.com/download_centre/Rel.4.0/TP/Full/setup.exe"
                target="_blank"
                className="updates_new_beta_div1_2"
              >
                Download TallyPrime Release 4.0
              </a>
            </div>
            <div className="updates_new_beta_mid">|</div>
            <div className="updates_new_beta_div1">
              {/* <div className="updates_new_beta_div2_sub1">
                <img
                  alt="beta"
                  src={beta_img}
                  className="updates_new_beta_div2_sub1_img"
                />
              </div> */}
              <a
                href="https://tallymirror.tallysolutions.com/download_centre/Rel.4.0/TPEL/Full/setup.exe"
                target="_blank"
                className="updates_new_beta_div1_2"
              >
                Download TallyPrime Edit Log Release 4.0
              </a>
            </div>
          </div>
          {/* <div className="updates_download_lists">
            <a
              className="updates_download_lists_a"
              href="https://tallymirror.tallysolutions.com/download_centre/Rel.3.0.1/TP/Full/setup.exe"
              target="_blank"
            >
              Tally Prime Release 3.0.1
            </a>

            <a
              className="updates_download_lists_a"
              href="https://tallymirror.tallysolutions.com/download_centre/Rel.3.0.1/TPEL/Full/setup.exe"
              target="_blank"
            >
              Tally Prime Edit Log Release 3.0.1
            </a>

            <a
              className="updates_download_lists_a"
              href="https://tallymirror.tallysolutions.com/download_centre/Rel.3.0_Gold/TPS/Full/setup.exe"
              target="_blank"
            >
              Tally Prime Server Release 3.0
            </a>

            <a
              className="updates_download_lists_a"
              href="https://tallymirror.tallysolutions.com/download_centre/Rel.3.0_Gold/TPD/Full/setup.exe"
              target="_blank"
            >
              Tally Prime Developer Release 3.0
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
