import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { caseStudies } from "./data";
import "../../styles/CaseStudies/Case.css";
import "../../styles/CaseStudies/List.css";
import "../../styles/CaseStudies/All.css";
import barcode from "../../Images/case_studies/barcode.webp";
import shared_img from "../../Images/case_studies/share.webp";
import shared_img2 from "../../Images/case_studies/share2.webp";
import read_img from "../../Images/case_studies/read2.webp";
import List2 from "./List";
import useMediaQuery from "@mui/material/useMediaQuery";
import ShareModal from "./Modal";
import "../../styles/Home/Access.css";
import { reversedUrlMaps } from "./data";

const MyComponent = ({ htmlString }) => {
  // Create a function to safely set HTML content
  const createMarkup = () => ({ __html: htmlString });

  return (
    <div>
      {/* Use dangerouslySetInnerHTML to render HTML content */}
      <div dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
};

export default function CaseUI2(props) {
  const navigate = useNavigate();
  const location = useLocation();
  //   const pathSegments = location.pathname.split("/");
  //   const lastPathValue = pathSegments[pathSegments.length - 1];

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const shareUrl = window.location.href;
  console.log(props.data);

  // let data = caseStudies[props.index];

  const device = useMediaQuery("(min-width:768px)");

  // console.log(data);
  // console.log(props.data[props.index]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  function modifyString(inputString) {
    // Replace spaces with hyphens
    let modifiedString = inputString.replace(/ /g, "-");

    // Remove special characters except hyphens
    modifiedString = modifiedString.replace(/[^a-zA-Z0-9-]/g, "");

    // Convert all capital letters to small letters
    modifiedString = modifiedString.toLowerCase();

    if (reversedUrlMaps[modifiedString])
      modifiedString = reversedUrlMaps[modifiedString];

    return modifiedString;
  }

  React.useEffect(() => {
    console.log("called");
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ShareModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        shareUrl={shareUrl}
      />
      <div className="">
        <div className="">
          <div style={{ width: "100%", paddingLeft: "5%", paddingRight: "1%" }}>
            <div style={{ width: "100%" }}>
              <img
                alt="img"
                src={props.data.img}
                style={{ marginBottom: "20px" }}
                loading="lazy"
              />
            </div>
            <h1 className="case_stuies_case_inner_div2_h1">
              {props.data.name}
            </h1>

            <div className="case_list_div_outer_inner_list_ind_div2_div1">
              <div className="case_list_div_outer_inner_list_ind_div2_div1_div1">
                <img
                  className="case_list_div_outer_inner_list_ind_div2_div1_div1_img"
                  alt="img"
                  src={read_img}
                  loading="lazy"
                />
                <span className="case_list_div_outer_inner_list_ind_div2_div1_div1_span">
                  {props.data.time}
                </span>
              </div>
              <div
                className="case_list_div_outer_inner_list_ind_div2_div1_div2_special"
                style={{ cursor: "pointer" }}
                onClick={openModal}
              >
                <img
                  className="case_list_div_outer_inner_list_ind_div2_div1_div1_img2"
                  alt="img"
                  src={shared_img}
                  loading="lazy"
                />
                <span className="case_list_div_outer_inner_list_ind_div2_div1_div1_span2">
                  Share
                </span>
              </div>
            </div>
            {!device && (
              <div className="case_stuies_case_inner_div1">
                <img
                  className="case_stuies_case_inner_div1_img"
                  alt="img"
                  src={props.data.img}
                  loading="lazy"
                />
              </div>
            )}
            <div
              className="case_stuies_case_inner_div2_div2"
              style={{
                position: "relative",
                zIndex: 9,
              }}
            >
              {props.data &&
                props.data.content &&
                props.data.content.slice(0, 3).map((item, index) => {
                  return (
                    <p className="case_stuies_case_inner_div2_div2_p">
                      <MyComponent htmlString={item} />
                    </p>
                  );
                })}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                zIndex: 10,
                marginTop: "-70px",
                background:
                  "radial-gradient(circle, rgba(255, 255, 255, 0.7) 10%, rgba(255, 255, 255, 0.8) 80%)",
              }}
            >
              <button
                className="access-button_class"
                style={{
                  backgroundColor: "white",
                  color: "#2C73A2",
                  border: "2px solid #2C73A2",
                  fontWeight: 600,
                }}
                onClick={() => {
                  navigate("/blogs/" + modifyString(props.data.name));
                }}
              >
                Continue Reading...
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{ marginTop: device ? "-20px" : "-50px" }}>
        <List2 />
      </div> */}
    </div>
  );
}
