import React from "react";
import "../../styles/CaseStudies/List.css";
import "../../styles/CaseStudies/All.css";
import barcode from "../../Images/case_studies/barcode.webp";
import shared_img from "../../Images/case_studies/share.webp";
import shared_img2 from "../../Images/case_studies/share2.webp";
import read_img from "../../Images/case_studies/read2.webp";
import { caseStudies as caseStudiesDataTemp } from "./data";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Case2UI from "./case2";
import Pagination from "@mui/material/Pagination";
import supabase from "../../Supabase";
import { connect } from "react-redux";
import { allBlogs } from "./data";
import { reversedUrlMaps } from "./data";

export default function AllUI() {
  const [selectedVal, setSelectedVal] = React.useState("al");
  const navigate = useNavigate();

  const [caseStudiesData, setCaseStudiesData] = React.useState([]);
  const [caseStudiesDataDisplay, setCaseStudiesDataDisplay] = React.useState(
    []
  );
  const [curIndex, setCurIndex] = React.useState(0);

  const device = useMediaQuery("(min-width:768px)");

  const [page, setPage] = React.useState(1);

  const [selectedItem, setSelectedItem] = React.useState(null);

  async function getAllRows() {
    try {
      // const { data, error } = await supabase.from("blogs").select("*");

      let data = allBlogs;

      // if (error) {
      //   throw error;
      // }

      console.log("Data");
      console.log(data);

      let temp = [];

      for (let i = 0; i < data?.length; i++) {
        let con = [];

        try {
          let tempcon = JSON.parse(data[i].content);
          // let tempcon = data[i].content;
          for (let j = 0; j < tempcon.length; j++) {
            con.push(tempcon[j].content);
          }
        } catch (err) {}

        console.log("CONNNNNNNNNNNNNNNN");
        console.log(con);

        temp.push({
          name: data[i].name,
          priority: i + 1,
          img: data[i].image,
          time: data[i].time,
          content: con,
          id: data[i].id,
        });
      }

      if (temp.length > 0) {
        setSelectedItem(temp[0]);
      }

      setCaseStudiesData(temp.slice().reverse());
      console.log(temp);
      console.log("All rows:", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  }

  React.useEffect(() => {
    getAllRows();
    // setCaseStudiesData(caseStudiesDataTemp);
  }, []);

  function modifyString(inputString) {
    // Replace spaces with hyphens
    let modifiedString = inputString.replace(/ /g, "-");

    // Remove special characters except hyphens
    modifiedString = modifiedString.replace(/[^a-zA-Z0-9-]/g, "");

    // Convert all capital letters to small letters
    modifiedString = modifiedString.toLowerCase();

    if (reversedUrlMaps[modifiedString])
      modifiedString = reversedUrlMaps[modifiedString];

    return modifiedString;
  }

  React.useEffect(() => {
    let temp = [];
    console.log("called2", page);
    for (
      let i = (page - 1) * 5;
      i < caseStudiesData.length && i < page * 5;
      i++
    ) {
      temp.push(caseStudiesData[i]);
    }

    console.log(temp);

    setCaseStudiesDataDisplay(temp);

    setCurIndex((page - 1) * 5);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [page, caseStudiesData]);

  // const caseStudiesData = [
  //   {
  //     name: "Tally Barcode Customizer",
  //     time: "8 min read",
  //     shareLink: "",
  //     image: "",
  //   },
  //   {
  //     name: "How Tally ERP Import Model is Useful ???",
  //     time: "8 min read",
  //     shareLink: "",
  //     image: "",
  //   },
  // ];

  return (
    <div>
      <div className="case_list_div_outer case_all_outer">
        <div className="case_list_div_inner">
          <div className="case_list_div_inner2">
            <div className="case_list_div_inner3">
              <h1 className="case_list_div_inner2_h1_2">All Blogs</h1>
              <div className="case_list_div_inner4_2">
                <div className="case_all_sortby">
                  <span className="case_all_sortby_span">Sort By</span>
                  <select
                    id="countries"
                    class="bg-white border border-gray-300 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 dark:text-white transition-colors duration-300 focus:border-black"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setSelectedVal(e.target.value);
                      let temp = [];

                      for (let i = 0; i < caseStudiesData.length; i++) {
                        temp.unshift(caseStudiesData[i]);
                      }

                      setCaseStudiesData(temp);
                    }}
                  >
                    <option
                      class="bg-white"
                      selected={selectedVal == "al"}
                      value="al"
                      style={{
                        fontWeight: selectedVal == "al" ? 600 : 400,
                      }}
                    >
                      Latest
                    </option>
                    {/* <option
                      class="bg-white"
                      value="mr"
                      selected={selectedVal == "mr"}
                      style={{
                        fontWeight: selectedVal == "mr" ? 600 : 400,
                      }}
                    >
                      Most Read
                    </option> */}
                    {/* <option
                      class="bg-white"
                      value="la"
                      selected={selectedVal == "la"}
                      style={{
                        fontWeight: selectedVal == "la" ? 600 : 400,
                      }}
                    >
                      Latest
                    </option> */}
                    <option
                      class="bg-white"
                      value="ol"
                      selected={selectedVal == "ol"}
                      style={{
                        fontWeight: selectedVal == "ol" ? 600 : 400,
                      }}
                    >
                      Oldest
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {device ? (
        <div className="case_list_div_outer case_all_outer">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "45%" }}>
              {selectedItem && <Case2UI index={curIndex} data={selectedItem} />}
            </div>
            <div style={{ width: "55%" }}>
              <div className="case_list_div_inner">
                <div className="case_list_div_inner2_2 case_all_inner">
                  {caseStudiesDataDisplay.map((item, index) => {
                    return (
                      <div
                        className="case_list_div_outer_outer_list_ind"
                        onClick={() => {
                          // navigate("/blogs/" + index);
                          // window.location.reload();
                          navigate("/blogs/" + modifyString(item.name));
                          // setCurIndex((page - 1) * 5 + index);

                          // if ("scrollRestoration" in window.history) {
                          //   window.history.scrollRestoration = "manual";
                          // }

                          // window.scrollTo(0, 0);

                          // setSelectedItem(item);
                        }}
                      >
                        <div className="case_list_div_outer_inner_list_ind">
                          <div className="case_list_div_outer_inner_list_ind_div2_div1_div2_new_mobile_share">
                            <img
                              className="case_list_div_outer_inner_list_ind_div2_div1_div1_img2"
                              alt="img"
                              src={shared_img}
                              loading="lazy"
                            />
                          </div>
                          <div className="case_list_div_outer_inner_list_ind_div1">
                            <img
                              alt="alt"
                              src={item.img}
                              className="case_list_div_outer_inner_list_ind_div1_img"
                              loading="lazy"
                            />
                          </div>
                          <div className="case_list_div_outer_inner_list_ind_div2">
                            <h1 className="case_list_div_outer_inner_list_ind_div2_h1">
                              {item.name}
                            </h1>
                            <div className="case_list_div_outer_inner_list_ind_div2_div1">
                              <div className="case_list_div_outer_inner_list_ind_div2_div1_div1">
                                <img
                                  className="case_list_div_outer_inner_list_ind_div2_div1_div1_img"
                                  alt="img"
                                  src={read_img}
                                  loading="lazy"
                                />
                                <span className="case_list_div_outer_inner_list_ind_div2_div1_div1_span">
                                  {item.time}
                                </span>
                              </div>
                              <div className="case_list_div_outer_inner_list_ind_div2_div1_div2">
                                <img
                                  className="case_list_div_outer_inner_list_ind_div2_div1_div1_img2"
                                  alt="img"
                                  src={shared_img}
                                  loading="lazy"
                                />
                                <span className="case_list_div_outer_inner_list_ind_div2_div1_div1_span2">
                                  Share
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="case_list_div_outer_outer_list_ind_divider"></div>
                      </div>
                    );
                  })}

                  {caseStudiesData && (
                    <div className="amsan_blogs_all_pagination">
                      <Pagination
                        count={
                          Math.floor(caseStudiesData.length / 5) +
                          (caseStudiesData.length % 5 ? 1 : 0)
                        }
                        size="large"
                        page={page}
                        onChange={(e, value) => setPage(value)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="case_list_div_outer case_all_outer">
          <div className="case_list_div_inner">
            <div className="case_list_div_inner2_2 case_all_inner">
              {caseStudiesDataDisplay.map((item, index) => {
                return (
                  <div
                    className="case_list_div_outer_outer_list_ind"
                    onClick={() => {
                      navigate("/blogs/" + modifyString(item.name));
                      window.location.reload();
                    }}
                  >
                    <div className="case_list_div_outer_inner_list_ind">
                      <div className="case_list_div_outer_inner_list_ind_div2_div1_div2_new_mobile_share">
                        <img
                          className="case_list_div_outer_inner_list_ind_div2_div1_div1_img2"
                          alt="img"
                          src={shared_img}
                          loading="lazy"
                        />
                      </div>
                      <div className="case_list_div_outer_inner_list_ind_div1">
                        <img
                          alt="alt"
                          src={item.img}
                          className="case_list_div_outer_inner_list_ind_div1_img"
                          loading="lazy"
                        />
                      </div>
                      <div className="case_list_div_outer_inner_list_ind_div2">
                        <h1 className="case_list_div_outer_inner_list_ind_div2_h1">
                          {item.name}
                        </h1>
                        <div className="case_list_div_outer_inner_list_ind_div2_div1">
                          <div className="case_list_div_outer_inner_list_ind_div2_div1_div1">
                            <img
                              className="case_list_div_outer_inner_list_ind_div2_div1_div1_img"
                              alt="img"
                              src={read_img}
                              loading="lazy"
                            />
                            <span className="case_list_div_outer_inner_list_ind_div2_div1_div1_span">
                              {item.time}
                            </span>
                          </div>
                          <div className="case_list_div_outer_inner_list_ind_div2_div1_div2">
                            <img
                              className="case_list_div_outer_inner_list_ind_div2_div1_div1_img2"
                              alt="img"
                              src={shared_img}
                              loading="lazy"
                            />
                            <span className="case_list_div_outer_inner_list_ind_div2_div1_div1_span2">
                              Share
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="case_list_div_outer_outer_list_ind_divider"></div>
                  </div>
                );
              })}

              <div className="amsan_blogs_all_pagination">
                {caseStudiesData && (
                  <div className="amsan_blogs_all_pagination">
                    <Pagination
                      count={
                        Math.floor(caseStudiesData.length / 5) +
                        (caseStudiesData.length % 5 ? 1 : 0)
                      }
                      size="large"
                      page={page}
                      onChange={(e, value) => setPage(value)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
