import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
// import OtpInput from "otp-input-react";
import OtpInput from "react-otp-input";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../../../Firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setNameA,
  setEmailA,
  setPhoneA,
  setCityA,
} from "../../../Redux/Cart/CartStore";
import { useNavigate } from "react-router-dom";
import { pushVerifiedDemoRequest } from "../../Functions/verifiedDemoCustomers";
import useMediaQuery from "@mui/material/useMediaQuery";

const App = () => {
  const [otp, setOtp] = useState("");
  //   const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(true);
  const [user, setUser] = useState(null);
  const ph = useSelector((state) => state.cart.phone_a);
  const name_a = useSelector((state) => state.cart.name_a);
  // const phone_a = useSelector((state) => state.cart.phone_a);
  const email_a = useSelector((state) => state.cart.email_a);
  const city_a = useSelector((state) => state.cart.city_a);

  const device = useMediaQuery("(min-width:768px)");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { state } = useLocation();
  // const { phone } = state;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    console.log("Data");
    console.log(name_a);
    console.log(email_a);
    console.log(city_a);
    console.log(ph);
  }, []);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + ph;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sent successfully!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        // setUser(res.user);

        const result = await pushVerifiedDemoRequest(
          name_a,
          ph,
          email_a,
          city_a
        );

        setLoading(false);

        if (result) {
          dispatch(setNameA(""));
          dispatch(setEmailA(""));
          dispatch(setCityA(""));
          dispatch(setPhoneA(""));

          navigate("/thank-you");
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  return (
    <section
      className="bg-[#2C73A2] flex items-center justify-center h-screen"
      style={{ marginBottom: "-60px" }}
    >
      <div>
        <Toaster toastOptions={{ duration: 4000 }} />
        {/* <div id="recaptcha-container" style={{ opacity: 0 }}></div> */}
        {user ? (
          <h2 className="text-center text-white font-medium text-2xl">
            👍Login Success
          </h2>
        ) : (
          <div className="w-80 flex flex-col gap-4 rounded-lg p-4">
            {showOTP ? (
              <>
                <div className="bg-white text-[#2C73A2] w-fit mx-auto p-4 rounded-full">
                  <BsFillShieldLockFill size={30} />
                </div>
                <label
                  htmlFor="otp"
                  className="font-bold text-xl text-white text-center"
                >
                  Enter your OTP
                </label>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={
                      <div style={{ width: device ? "20px" : "15px" }}></div>
                    }
                    renderInput={(props) => <input {...props} />}
                    isInputNum
                    // className="opt-container"
                    containerStyle={{
                      marginTop: "15px",
                      marginBottom: "10px",
                    }}
                    inputStyle={{
                      backgroundColor: "white",
                      width: device ? "40px" : "35px",
                      height: device ? "40px" : "35px",
                      color: "black",
                    }}
                  />
                </div>
                <button
                  onClick={onOTPVerify}
                  className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Verify OTP</span>
                </button>
              </>
            ) : (
              <>
                <div className="bg-white text-[#2C73A2] w-fit mx-auto p-4 rounded-full">
                  <BsTelephoneFill size={30} />
                </div>
                <label
                  htmlFor=""
                  className="font-bold text-xl text-white text-center"
                >
                  Verify your phone number
                </label>
                <PhoneInput
                  country={"in"}
                  value={ph}
                  onChange={(e) => {
                    console.log(e);
                    dispatch(setPhoneA(e));
                  }}
                />
                <button
                  onClick={onSignup}
                  className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Send code via SMS</span>
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default App;
