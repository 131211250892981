import supabase from "../../Supabase";

const fetchIpAddress = async (user_d) => {
  try {
    const response = await fetch("https://ipapi.co/json");
    const data = await response.json();

    console.log(data);

    const { data: newRecord, error } = await supabase
      .from("ip_addresses")
      .insert([
        {
          ip: data?.ip,
          user_details: user_d,
          ip_details: data,
        },
      ]);

    if (newRecord) {
      console.log("IP Pushed");
      console.log(newRecord);
    }

    if (error) {
      console.log("error");
      console.log(error);
    }
  } catch (error) {
    console.error("Error fetching IP address:", error);
  }
};

export { fetchIpAddress };
