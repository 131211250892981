import { ErrorBoundary } from "react-error-boundary";

export default function ErrorBoundaryWrapper({ children }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
}

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="my-10 min-h-[60vh] flex flex-col justify-center items-center  lg:mx-28">
      <main className="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-primary">Ohh No</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-neutral-900 sm:text-5xl">
            Something went wrong :(
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Please try again later. We will be back soon
          </p>
        </div>
      </main>
    </div>
  );
}
