import { useCallback, useEffect, useState } from "react";

const useScrollPosition = (ref) => {
  //Scroll Progress ranges from 0 to 1
  const [scrollProgress, setScrollProgress] = useState({
    x: 0,
    y: 0,
  });

  const isScrollable = {
    x: ref?.current ? ref.current.scrollWidth > ref.current.clientWidth : false,
    y: ref?.current
      ? ref.current.scrollHeight > ref.current.clientHeight
      : false,
  };

  useEffect(() => {
    if (!ref || !ref.current) return;
    const handleScroll = () => {
      if (!ref || !ref.current) return;
      setScrollProgress((prev) => ({
        ...prev,
        x:
          ref.current.scrollLeft /
          (ref.current.scrollWidth - ref.current.clientWidth),
        y:
          ref.current.scrollTop /
          (ref.current.scrollHeight - ref.current.clientHeight),
      }));
    };
    const element = ref.current;
    element.addEventListener("scroll", handleScroll);
    return () => element.removeEventListener("scroll", handleScroll);
  }, [ref]);

  //ScrollX and ScrollY takes an offset argument
  //Scroll the ref element by the offset
  const scrollX = useCallback(
    (offset) => {
      if (!ref || !ref.current) return;
      const newScrollLeft = clamp(
        ref.current.scrollLeft + offset,
        0,
        ref.current.scrollWidth
      );
      ref.current.scroll({
        left: newScrollLeft,
        behavior: "smooth",
      });
    },
    [ref]
  );

  const scrollY = useCallback(
    (offset) => {
      if (!ref || !ref.current) return;
      const newScrollTop = clamp(
        ref.current.scrollTop + offset,
        0,
        ref.current.scrollHeight
      );
      ref.current.scroll({
        top: newScrollTop,
        behavior: "smooth",
      });
    },
    [ref]
  );

  return { scrollProgress, scrollX, scrollY, isScrollable };
};

export default useScrollPosition;

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);
