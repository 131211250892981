import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD13SIFCNycuoJk4m2cCOH5DDDVLYvqH7Y",
  authDomain: "amsan-bf40a.firebaseapp.com",
  projectId: "amsan-bf40a",
  storageBucket: "amsan-bf40a.appspot.com",
  messagingSenderId: "362597412198",
  appId: "1:362597412198:web:9d5339c6d30e0e38e36ca7",
  measurementId: "G-RPL9WZH94P",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { app, auth };
