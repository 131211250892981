import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
    openCart: false,
    user: null,
    firstName: "",
    lastName: "",
    phoneNo: "",
    country: "India",
    billing_address: "",
    shipping_address: "",
    city: "",
    state: "",
    postal_code: "",
    storageName: "figrycart",
    showModal: false,
    name_a: "",
    phone_a: "",
    email_a: "",
    city_a: "",
    fName: "",
    lName: "",
    cEmail: "",
    requirement: "",
  },
  reducers: {
    setFName: (state, action) => {
      state.fName = action.payload;
    },
    setLName: (state, action) => {
      state.lName = action.payload;
    },
    setCEmail: (state, action) => {
      state.cEmail = action.payload;
    },
    setRequirement: (state, action) => {
      state.requirement = action.payload;
    },
    setNameA: (state, action) => {
      state.name_a = action.payload;
    },
    setPhoneA: (state, action) => {
      state.phone_a = action.payload;
    },
    setEmailA: (state, action) => {
      state.email_a = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setCityA: (state, action) => {
      state.city_a = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNo = action.payload;
    },
    setBillingAddress: (state, action) => {
      state.billing_address = action.payload;
    },
    setShippingAddress: (state, action) => {
      state.shipping_address = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setPostalCode: (state, action) => {
      state.postal_code = action.payload;
    },
    setCartItems: (state, action) => {
      state.cartItems = action.payload;
    },
    setOpenCart: (state, action) => {
      state.openCart = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {
  setCartItems,
  setOpenCart,
  setUser,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setBillingAddress,
  setShippingAddress,
  setCity,
  setState,
  setPostalCode,
  setShowModal,
  setNameA,
  setPhoneA,
  setEmailA,
  setCityA,
  setFName,
  setLName,
  setCEmail,
  setRequirement,
} = cartSlice.actions;

export default cartSlice.reducer;
