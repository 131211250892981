import case1 from "../../Images/case_studies/case1.jpg";
import case2 from "../../Images/case_studies/case2.jpg";
import case3 from "../../Images/case_studies/case3.jpg";
import case4 from "../../Images/case_studies/case4.jpg";
import case5 from "../../Images/case_studies/case5.jpg";

const caseStudies = [
  {
    name: "How Netapp Benefited From SAP To Tally ERP 9 Integration???",
    time: "5 min read",
    img: case1,
    priority: 1,
    content: [
      "‘Netapp India,’ founded in 1992, is an American based organization, headquartered in Sunnyvale, California, has India as one of its host countries. ‘Netapp India’ is an American Hybrid cloud data services and data management company, which is also a global leader in the sector of cloud computing. It is a well-known, established organization having its wings widespread over a large number of countries. This company has also been ranked in the fortune 500 since 2012. Netapp India makes use of SAP software to maintain their various needs of the concern. The company finds SAP to be a useful software for their demands. Having its one wing in our country, the organization must prepare all their financial statements according to the various rules and regulations of the Indian Accounting Standards. Saving all the company’s data over SAP had become an issue for this company as SAP does not maintain its accounting or financial data according to the Indian Accounting System. Therefore preparing Financial statements had become an issue for the company.",
      "The primary reason why preparing’ financial statements’ had become an issue is that Netapp India uses SAP software for all their accounting and business requirements. SAP does not provide its users with the feature of preparing financial statements according to Indian Accounting Standards and principles’ rules and regulations. SAP is an excellent software providing other such services, but it lacks the feature of preparing financial statements according to the Indian Accounting Standards.",
      "Netapp India approached Amsan Technology to resolve this issue. Amsan Technology, founded in 2007, is an established organization and is a 5 star certified Tally partner. Amsan Technology, having over 3100 clients, has over a decade of experience in this sector.  Byjus, Raymond, ISRO, Prestige, Tommy Hilfiger, Netapp, and others are a few of their top clients. Amsan Technology suggested Netapp India do SAP to Tally integration.",
      "Speaking of SAP to Tally integration, let me brief you with its essential benefits and why Tally integration with SAP is necessary. SAP is in international software, whereas Tally ERP is a simple software with GST and other features and works with the accordance of the Indian Accounting Standards. With Tally integration, all the data saved in SAP can be easily transferred to Tally. Once the data is transferred, the everyday data entry in SAP can be automatically synchronized without any manual interference. As already mentioned earlier, SAP lacks the Financial statement feature in accordance to the rules and regulations put forward by the  Indian Accounting Standards. It is at this point that the Tally ERP software comes to the rescue of Netapp India.",
      "With SAP to Tally integration, Netapp India could easily transfer all the data saved over SAP, to Tally without any difficulties. And the data thus transferred are retained and collected over Tally and are utilized to provide a financial statement for the organization in accordance to the rules and regulations of the Indian Accounting Standards. SAP to Tally integration reduces the redundancy by 70%. It also increases the productivity and performance of the company by 60%. Apart from these, it also decreases the cost by 40%. The data saved in SAP in the daily working of the organization could be easily synchronized to Tally without creating any chaos.",
      "Netapp India smartly considered the SAP to Tally integration, and they could provide the financial statements in accordance to the various rules and regulation of the Indian Accounting Standards. Therefore, Netapp India became one of the many happy clients of Amsan Technology.",
      "If your company faces such similar issues, kindly consider SAP to Tally integration with Amsan Technology. With SAP to tally integration, your company can reap crazy profits with minimal costs. Whether an Indian company or a company having a branch in India, preparing Financial statements must be in accordance with the various rules and regulations of the Indian Accounting Standards.",
    ],
    link: "https://amsantechnology.com/case-study/how-netapp-benefited-from-sap-to-tally-erp-9-integration-2",
  },
  {
    name: "Journey Of Green Chef… Tally Integration- Manufacturing Model???",
    time: "5 min read",
    img: case2,
    priority: 2,
    content: [
      "Tally ERP 9 is a spectacular software that allows its users to utilize its features to its fullest extent. There are numerous options available to its users to explore the Tally ERP software. Tally ERP acts as a foundation stone on which almost all the enterprises can be built on. It is the most straightforward, flexible, and most manageable software to use. One unique aspect of Tally ERP 9 is that it can be integrated and customized according to its users’ needs and requirements.",
      "‘Green Chef’ is a company whose main motive revolves around the manufacturing sector. Green Chef is into manufacturing a wide range of appliances, cookware, and kitchen accessories. As this company manufactured a wide range of products at various locations, handling and synchronizing the factories’ output and working had become an issue for this company. They wished for a simpler software that could synchronize all their data and working. They wanted a simpler system that could organize their data, financial requirements, process, and outputs.",
      "They approached Amsan Technology, who suggested them to consider Tally Manufacturing Module integration. Amsan Technology is a reputed 5-star certified tally partner. Amsan technology has over a decade of experience in this sector. Byjus, Raymonds, Netapp, Prestige are a few of their top clients to name.",
      "Tally manufacturing module proved beneficial for Green Chef as they could deploy Tally ERP in a decentralized model in individual factory units at various locations. They also could have centralized servers with their users logging in from multiple locations. Tally ERP integrated all the departments of the manufacturing concern and made their working look effortless and magical. It further reduced their costs and increased their productivity by 60%.",
      "If you have an organization that is into manufacturing and you want the organization to work efficiently without facing any hurdles, considering tally integration would be a smart choice. Feel free to Amsan Technology as they could help you with Tally manufacturing module integration. By utilizing this Tally ERP feature, you can effectively integrate your business concern, optimize the whole supply chain, identify market demands, and many other features.",
    ],
    link: "https://amsantechnology.com/case-study/journey-of-green-chef-tally-integration-manufacturing-model-2",
  },
  {
    name: "How Tally ERP Import Model Is Useful???",
    time: "5 min read",
    img: case3,
    priority: 3,
    content: [
      "Tally ERP is an unbelievably incredible software that gives its users various features to explore in order to satisfy their needs and requirements. One such feature is Tally ERP Import integration module.",
      "Tally ERP Import integration model….this may sound a little complicated. To break this down, let us look at how Amsan Technology, with the help of Tally solution’s Tally ERP Import integration model, helped KNK constructions.",
      "KNK Constructions is a Bengaluru based construction company. Before using Tally ERP Import integration, this company used Microsoft excel sheets to store their data such as financial data, inventory, payroll details, and others. With the Tally ERP Import model’s help, KNK Constructions could quickly transfer their data saved over excel to Tally ERP without facing any hurdles. This integration can also transfer large-sized data effortlessly to Tally, and they can file their GST with no sweat.",
      "This feature of Tally enables its users to transfer their data stored from anywhere, r over excel, or any other medium with just a single click. Moving large data is now just a click away with the help of Tally ERP Import model.",
      "The Tally ERP Import model’s main benefit is that it works effortlessly with ease and instant implementation. Bulk entries are made without a hitch by a single click. It is user friendly and requires minimum knowledge to integrate. It is easy, simple, and time-saving. It also increases the productivity of the firm.",
    ],
    link: "https://amsantechnology.com/case-study/how-tally-erp-import-model-is-useful-2",
  },
  {
    name: "Tally ERP Barcode Customization!!!",
    time: "5 min read",
    img: case4,
    priority: 4,
    content: [
      "Nova times is a reputed company located in Bengaluru dealing with a wide variety of watches and clocks. There were facing stock control issues as they had a wide variety of goods on the shelves. They approached Amsan Technology and expressed their ongoing business issues. Amsan Technology is a Bengaluru based firm, dealing with a wide range of software and Tally ERP 9 is one of them. Amsan Technology has an experiance of over a decade, and they are the best in this sector.",
      "Amsan Technology advised Nova times to try out Tally ERP Barcode Customisation. If the question of what is Tally Barcode Customisation is pondering in your mind, let me help you clear that off. Barcodes are a necessary form of inventory management; with just a scan, the users can save time, reduce duplication of work, and increase the firm’s productivity. ",
      "A few advantages of Barcode customization are as follows: \n\n 1) It boosts the efficiency of the firm and saves time. \n 2) It instantly updates the firm’s inventory management system. \n 3) The firms enjoy greater data accuracy. \n 4) The firms can manage inventory management at multiple locations.",
      "Apart from the primary benefits of Tally Barcode customizations, here are a few reasons why you should consider Tally Barcode customization for your firm. \n\n 1) It is user-friendly and cost-effective. \n 2) It ensures high data accuracy \n 3)Barcodes provide security for inventories as well. \n 4) It can help manage inventories at multiple locations as well.",
      "If you are looking for Barcode customizations, don’t think twice to contact Amsan Technology as they are the best in this sector.",
    ],
    link: "https://amsantechnology.com/case-study/tally-erp-barcode-customization",
  },
  {
    name: "How Kundan Electronics Benefited From Tally Integration???",
    time: "5 min read",
    img: case5,
    priority: 5,
    content: [
      "E-commerce!!! For a few, this word sounds crazy and scary, but on the contrary, there do exist quite a lot of people around the globe who are comfortable with this word, and this word now is their source of livelihood. Defining this word in a layman’s way is that- e-commerce is simply put, commerce in an electronic atmosphere. Commerce includes buying or selling of goods or services with a monetary value attached for the same. Commerce is trading of goods or services for the money’s worth. This ‘commerce’ done in an electronic platform is termed as e-commerce.",
      "The world is running on e-commerce. The best examples of e-commerce are Flipkart, Amazon, Snapdeal, shine, club factory, etc. These platforms are open to all the sellers who wish for their products to be sold online. A seller can sell his goods or services online via any of the e-commerce platforms.",
      "Kundan electronics is one such seller who wishes to sell their goods on Flipkart. Kundan electronics is now a seller of electronic products on Flipkart, as he is a registered trader, and he has access to a broader audience. Flipkart or any e-commerce websites, after trading their goods, enter their data on excel sheets and send them to their respective sellers. The registered sellers then have to re-enter the whole excel sheets manually into Tally. Only then, they could proceed further for the remaining accounting-related stuff like GST filing and others.",
      "This duplication of work is tedious in nature and takes up a lot of their productive time. At this point, Kundan electronics realized that they could no longer manually enter this data and that they need a solution for the same.",
      "Kundan electronics encountered Amsan Technology and explained to them their problematic situation. Amsan Technology is a reputed 5-star certified tally partner. Amsan tecchnology has over a decade of experience in this sector. Byjus, Raymonds, Netapp, Prestige are a few of their top clients to name. Amsan Technology suggested Kundan electronics their Tally Integration Tool. With the help of Amsan Technology’s Tally Integration Tool, Kundan electronics were able to create financial statements like debit notes, sales invoice, credit notes, profit and loss accounts, balance sheets and other excel sheets downloaded from the e-commerce websites like Flipkart, Amazon, and others directly into Tally ERP. This proved to be a fantastic tool as it reduced the manual, tedious typing from excel sheets to Tally ERP.",
      "Talking about its advantages, with tally integration, Kundan electronics had to no longer deal with the duplication of work. It saved their precious time and costs and also increased their productivity by 60%. Moreover, it reduced errors drastically and helped to maintain data integrity.",
      "If your company faces such issues, feel free to contact Amsan Technology, and they can provide your company with the tally integration tool. With the help of tally integration tool, you can unleash a lot of benefits. Don’t think twice about getting your tally integration done from Amsan technology. They are the best in this field.",
    ],
    link: "https://amsantechnology.com/case-study/how-kundan-electronics-benifited-from-tally-integration",
  },
];

export { caseStudies };
