import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { caseStudies } from "./data";
import "../../styles/CaseStudies/Case.css";
import "../../styles/CaseStudies/List.css";
import "../../styles/CaseStudies/All.css";
import barcode from "../../Images/case_studies/barcode.webp";
import shared_img from "../../Images/case_studies/share.webp";
import shared_img2 from "../../Images/case_studies/share2.webp";
import read_img from "../../Images/case_studies/read2.webp";
import List2 from "./List";
import useMediaQuery from "@mui/material/useMediaQuery";
import ShareModal from "./Modal";
import supabase from "../../Supabase";
import { useDispatch, useSelector } from "react-redux";
import { setShowModal } from "../../Redux/Cart/CartStore";
import FormUI from "../Utilities/FormModal";
import { useState } from "react";
import { addItemToStore } from "../Functions/LocalStorageFunctions/AddToStore";
import { deleteFromStore } from "../Functions/LocalStorageFunctions/DeleteFromStore";
import { getItemFromStore } from "../Functions/LocalStorageFunctions/GetStore";
import { UpdateItemInStore } from "../Functions/LocalStorageFunctions/UpdateStore";
import { Timer } from "@mui/icons-material";
import { allBlogs } from "./data";
import { reversedUrlMaps, urlMaps } from "./data";

const MyComponent = ({ htmlString }) => {
  // Create a function to safely set HTML content
  const createMarkup = () => ({ __html: htmlString });

  return (
    <div>
      {/* Use dangerouslySetInnerHTML to render HTML content */}
      <div dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
};

const CaseUI = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  let lastPathValue = pathSegments[pathSegments.length - 1];

  if (reversedUrlMaps[lastPathValue]) window.location.href = "/";

  if (urlMaps[lastPathValue]) lastPathValue = urlMaps[lastPathValue];

  console.log(lastPathValue);
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const shareUrl = window.location.href;
  const formOpen = useSelector((state) => state.cart.showModal);

  const [startTime, setStartTime] = useState(new Date());
  const [totalTime, setTotalTime] = useState(0);

  const PAGE_NAME = "BLOGS";

  const device = useMediaQuery("(min-width:768px)");
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  // addItemToStore(PAGE_NAME, data.id, {
  //   name: data.name,
  //   totalTime: totalTime.toString(),
  // });

  const [seconds, setSeconds] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);

  var timer;

  const canonicalUrl = window.location.origin + location.pathname;

  React.useEffect(() => {
    const metaRobots = document.createElement("meta");
    metaRobots.name = "robots";
    metaRobots.content = "index, follow";

    document.head.appendChild(metaRobots);

    return () => {
      document.head.removeChild(metaRobots);
    };
  }, []);

  React.useEffect(() => {
    const canonicalLink = document.createElement("link");
    canonicalLink.rel = "canonical";
    canonicalLink.href = canonicalUrl;

    document.head.appendChild(canonicalLink);

    return () => {
      document.head.removeChild(canonicalLink);
    };
  }, [canonicalUrl]);

  React.useEffect(() => {
    document.title = data?.name;

    return () => {
      document.title = "Amsan Technology";
    };
  }, [data]);

  React.useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", data?.desc);
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content = data?.desc;
      document.head.appendChild(newMetaDescription);
    }

    return () => {
      const metaDescriptionToRemove = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescriptionToRemove) {
        document.head.removeChild(metaDescriptionToRemove);
      }
    };
  }, [data]);

  React.useEffect(() => {
    return () => {
      console.log("About component unmounted!");
      insertData(localStorage.getItem(process.env.REACT_APP_LOCAL_STORE));
    };
  }, []);

  const insertData = async (dat) => {
    console.log("Insert data", dat);
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      const { data, error } = await supabase.from("blog_users").upsert([
        {
          email: user?.email,
          data: dat,
        },
      ]);

      if (error) {
        throw error;
      }

      console.log("Data inserted successfully:", data);
    } catch (error) {
      console.error("Error inserting data:", error.message);
    }
  };

  function modifyString(inputString) {
    // Replace spaces with hyphens
    let modifiedString = inputString.replace(/ /g, "-");

    // Remove special characters except hyphens
    modifiedString = modifiedString.replace(/[^a-zA-Z0-9-]/g, "");

    // Convert all capital letters to small letters
    modifiedString = modifiedString.toLowerCase();

    return modifiedString;
  }

  React.useEffect(() => {
    timer = setInterval(() => {
      setSeconds(seconds + 1);

      if (seconds == 59) {
        setMinutes(minutes + 1);
        setSeconds(0);
      }
    }, 1000);

    return () => {
      if (data) {
        addItemToStore(PAGE_NAME, data.id, {
          name: data.name,
          totalTime: minutes + ":" + seconds,
        });
      }
      console.log("called");
      // insertData(localStorage.getItem(process.env.REACT_APP_LOCAL_STORE));
      clearInterval(timer);
    };
  });
  async function getAllRows() {
    try {
      // const { data, error } = await supabase
      //   .from("blogs")
      //   .select("*")
      //   .eq("name", lastPathValue?.replace(/-/g, " "));

      // if (error) {
      //   throw error;
      // }

      let tempData = allBlogs;
      let data = [];

      for (let k = 0; k < tempData.length; k++) {
        if (modifyString(lastPathValue) == modifyString(tempData[k].name)) {
          data.push(tempData[k]);
          break;
        }
      }

      console.log("Case data");
      console.log(data);
      let temp = [];

      for (let i = 0; i < data?.length; i++) {
        let con = [];
        let typ = [];
        console.log("called heresjsjsk");
        try {
          let tempcon = [];

          if (data[i].json) tempcon = data[i].content;
          else tempcon = JSON.parse(data[i].content);
          // let tempcon = data[i].content;
          console.log("tempcon");
          console.log(tempcon);
          console.log("called heresjsjsk2");
          for (let j = 0; j < tempcon.length; j++) {
            if (tempcon[j].type == "img") {
              con.push({
                alt: tempcon[j].alt,
                url: tempcon[j].url,
              });
            } else {
              con.push(tempcon[j].content);
            }
            typ.push(tempcon[j].type);
          }
        } catch (err) {}

        temp.push({
          name: data[i].name,
          priority: i + 1,
          img: data[i].image,
          time: data[i].time,
          content: con,
          id: data[i].id,
          typ: typ,
          scriptTags: data[i]?.scriptTags,
          desc: data[i].desc,
        });
      }

      if (temp.length > 0) {
        setData(temp[0]);

        setLoaded(true);
      } else {
        window.location.href = "/";
      }

      console.log("All rows:", data);
      return data;
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  }

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  React.useEffect(() => {
    getAllRows();

    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, []);

  const getHeadingStyles = (type) => {
    let fontWeight, fontSize;

    switch (type) {
      case "h1":
        fontWeight = 600;
        fontSize = "35px";
        break;
      case "h2":
        fontWeight = 500;
        fontSize = "30px";
        break;
      case "h3":
        fontWeight = 500;
        fontSize = "23px";
        break;
      // You can add more cases for other heading types if needed
      default:
        fontWeight = 400;
        fontSize = "18px";
    }

    return { fontWeight, fontSize };
  };

  return (
    <div>
      {/* <script type="application/ld+json">
        {{
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": document.location.href,
          },
          headline: title,
          description: description,
          articleBody: modifiedContent,
          // Add other schema properties as needed
        }}
      </script> */}
      <ShareModal />
      {data && (
        <div className="case_stuies_case_outer">
          <div className="case_stuies_case_inner">
            {/* {device && (
              <div className="case_stuies_case_inner_div1">
                <img
                  className="case_stuies_case_inner_div1_img"
                  alt={data?.name}
                  src={data.img}
                  loading="lazy"
                />
              </div>
            )} */}
            <div className="case_stuies_case_inner_div2">
              {data.scriptTags}
              {device && (
                <div className="case_stuies_case_inner_div1">
                  <img
                    className="case_stuies_case_inner_div1_img"
                    alt={data?.name}
                    src={data.img}
                    loading="lazy"
                  />
                </div>
              )}
              <h1 className="case_stuies_case_inner_div2_h1">{data.name}</h1>

              <div className="case_list_div_outer_inner_list_ind_div2_div1">
                <div className="case_list_div_outer_inner_list_ind_div2_div1_div1">
                  <img
                    className="case_list_div_outer_inner_list_ind_div2_div1_div1_img"
                    alt="img"
                    src={read_img}
                    loading="lazy"
                  />
                  <span className="case_list_div_outer_inner_list_ind_div2_div1_div1_span">
                    {data.time}
                  </span>
                </div>
                <div
                  className="case_list_div_outer_inner_list_ind_div2_div1_div2_special"
                  style={{ cursor: "pointer" }}
                  onClick={openModal}
                >
                  <img
                    className="case_list_div_outer_inner_list_ind_div2_div1_div1_img2"
                    alt="img"
                    src={shared_img}
                    loading="lazy"
                  />
                  <span className="case_list_div_outer_inner_list_ind_div2_div1_div1_span2">
                    Share
                  </span>
                </div>
              </div>

              {!device && (
                <div className="case_stuies_case_inner_div1">
                  <img
                    // className="case_stuies_case_inner_div1_img"
                    alt="img"
                    src={data.img}
                    style={{ marginTop: "15px", borderRadius: "10px" }}
                    loading="lazy"
                  />
                </div>
              )}
              <div className="case_stuies_case_inner_div2_div2">
                {data &&
                  data.content &&
                  data.content.map((item, index) => {
                    console.log("Itemmmmmmmmmmmmmmmmmm");

                    console.log(item);
                    const { typ } = data;
                    const headingType = typ[index];
                    const { fontWeight, fontSize } =
                      getHeadingStyles(headingType);

                    return data?.typ[index] == "ol" ? (
                      <div>
                        {item?.map((ig, ind) => {
                          return (
                            <div>
                              <p>{ig}</p>
                              <div style={{ height: "10px" }}></div>
                            </div>
                          );
                        })}
                        <br />
                      </div>
                    ) : data?.typ[index] == "img" ? (
                      <div>
                        {" "}
                        <img
                          className="case_stuies_case_inner_div1_img"
                          alt={item.alt}
                          src={item.url}
                          style={{ marginTop: "15px", borderRadius: "7px" }}
                          loading="lazy"
                        />
                        <br />
                        <br />
                      </div>
                    ) : (
                      <p
                        key={index}
                        className="case_stuies_case_inner_div2_div2_p"
                      >
                        <Heading
                          type={headingType}
                          styles={{ fontWeight, fontSize }}
                          key={index}
                        >
                          <MyComponent htmlString={item} />
                        </Heading>
                      </p>
                    );
                  })}

                <button
                  className="bonus-button_class"
                  onClick={() => {
                    dispatch(setShowModal(true));
                    navigate("/");
                  }}
                  style={{
                    color: "white",
                    padding: "10px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    borderRadius: "5px",
                    backgroundColor: "#2c73a2",
                    marginTop: "20px",
                    marginBottom: "50px",
                  }}
                >
                  Get free tally modules
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div style={{ marginTop: device ? "-20px" : "-50px" }}>
        {/* <List2 /> */}
      </div>
    </div>
  );
};

const Heading = ({ type, styles, children }) => {
  return React.createElement(type, { style: styles }, children);
};

export default CaseUI;
