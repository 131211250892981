import React from "react";
import "../../styles/Home/Reviewes.css";
import { forwardRef, useRef } from "react";
import { useScrollPosition } from "../../hooks/index";
import f1 from "../../Images/home/Reviews/f1.webp";
import f2 from "../../Images/home/Reviews/f2.webp";
import m1 from "../../Images/home/Reviews/m1.webp";
import featured_img from "../../Images/case_studies/featured.webp";
import shared_img from "../../Images/case_studies/share.webp";
import read_img from "../../Images/case_studies/read.webp";
import commo from "../../Images/home/Reviews/commo.webp";
import { BiChevronRight } from "react-icons/bi";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { setShowModal } from "../../Redux/Cart/CartStore";
// import rateus from "../../Images/home/Reviews/rateus.webp";
import "../../styles/CaseStudies/List.css";
import { caseStudies as data } from "./data";
import { useNavigate, useLocation } from "react-router-dom";

// const data = [
//   {
//     id: "1",
//     img: m1,
//     name: "Laxman SME",
//     ratings: 5,
//     review:
//       "From SM Enterprises, Tally Service Super & Any Time Your  Supported All Members .Thanking you",
//   },
//   {
//     id: "2",
//     img: f1,
//     name: "Rekha Patil",
//     ratings: 5,
//     review: "Excellent service and they give quick support.",
//   },
//   {
//     id: "3",
//     img: m1,
//     name: "Gopal Rathod",
//     ratings: 5,
//     review:
//       "Best of services from amsan technology  .get your job done in minutes . well trained staff  to solve your problems.",
//   },
//   {
//     id: "4",
//     img: f2,
//     name: "Rohini Hugar",
//     ratings: 5,
//     review:
//       "very good service provided by team bes solution and advices and support provided by team",
//   },
//   {
//     id: "5",
//     img: m1,
//     name: "Brijesh LR",
//     ratings: 5,
//     review: "The service is really good. Looking forward to buy more products",
//   },
// ];

const ItemSkeleton = (props) => {
  return (
    <div
      className="flex aspect-square w-48 shrink-0 animate-pulse flex-col items-center overflow-hidden rounded-sxl border bg-neutral-200 text-center shadow-md duration-1000 hover:shadow-lg"
      {...props}
    >
      <div className="h-36 w-full shrink-0 bg-neutral-300 "></div>
      <div className="grid h-8 w-full grow place-items-center bg-neutral-200 p-1"></div>
    </div>
  );
};

export default function CaseListUI() {
  const scrollContainerRef = useRef(null);

  const { scrollX } = useScrollPosition(scrollContainerRef);

  const scrollLeft = () => scrollX(-800);

  const scrollRight = () => scrollX(800);

  const device = useMediaQuery("(min-width:768px)");

  const formOpen = useSelector((state) => state.cart.showModal);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <div>
      <div className="case_list_div_outer">
        <div className="case_list_div_inner">
          <div className="case_list_div_inner2">
            <div className="case_list_div_inner3">
              <h1 className="case_list_div_inner2_h1">Featured Case studies</h1>

              <div className="case_list_div_inner4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 32 32"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-12 h-12 case_list_div_inner4_svg"
                  color="#2c73a2"
                  onClick={scrollLeft}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 32 32"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-12 h-12 case_list_div_inner4_svg"
                  color="#2c73a2"
                  onClick={scrollRight}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
            <div>
              <div className="relative w-full">
                {!device && (
                  <div
                    className="absolute left-0 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 rotate-180 cursor-pointer rounded-full border bg-white p-0.5 text-neutral-600 shadow-md animate-in"
                    onClick={scrollLeft}
                    style={{ zIndex: 8, position: "absolute" }}
                  >
                    <BiChevronRight size={25} />
                  </div>
                )}

                {!device && (
                  <div
                    className="absolute right-5 top-1/2 z-10 translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-full border  bg-white p-0.5 text-neutral-600 shadow-md"
                    onClick={scrollRight}
                    style={{ zIndex: 8, position: "absolute" }}
                  >
                    <BiChevronRight size={25} />
                  </div>
                )}
                <div
                  className="no-scrollbar overflow-x-scroll case_list_scrollbar"
                  ref={scrollContainerRef}
                >
                  <div className="flex gap-x-5 no-scrollbar case_list_inner3_outer_outer">
                    {(!data || data.length === 0) &&
                      Array(5)
                        .fill(0)
                        .map((_, i) => <ItemSkeleton key={i} />)}
                    {data &&
                      data.length > 0 &&
                      data?.map((item, index) => (
                        <CarouselItem
                          key={index + "jag"}
                          item={item}
                          len={index}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const CarouselItem = forwardRef(({ item, len }, ref) => {
  const navigate = useNavigate();

  const containerStyle = {
    position: "relative",
    overflow: "hidden",
    background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${item.img})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "white",
    textAlign: "center",
    padding: "20px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "300px",
    width: "100%",
    borderRadius: "7px",
  };

  const overlayStyle = {
    content: '""',
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9))",
    pointerEvents: "none",
  };

  const headingStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
    position: "relative",
    zIndex: "1",
  };

  const textStyle = {
    fontSize: "18px",
    position: "relative",
    zIndex: "1",
  };

  const urlMaps = {
    0: "how-netapp-benefited-from-sap-to-tally-erp-9-integration",
    1: "journey-of-green-chef",
    2: "how-tally-erp-import-model-is-useful",
    3: "tally-erp-barcode-customization",
    4: "how-kundan-electronics-benefited-from-tally-integration",
  };

  return (
    <div
      className="case_list_inner3_outer case_list_hover_css"
      ref={ref}
      key={item}
      onClick={() => {
        // const link = document.createElement("a");
        // link.href = item.link;
        // link.target = "_blank";
        // link.rel = "noopener noreferrer";
        // link.click();

        navigate("/case-studies/" + urlMaps[len]);

        window.location.reload();
      }}
    >
      <div style={containerStyle}>
        <div style={overlayStyle}></div>
        <div className="case_list_inner3_outer_img_div_list_outer_div">
          <img
            src={featured_img}
            alt="featured"
            className="case_list_inner3_outer_img_div_list"
            loading="lazy"
          />
          <img
            src={shared_img}
            alt="featured"
            loading="lazy"
            className="case_list_inner3_outer_img_div_list_shared "
          />
        </div>
        <div>
          <h1 className="case_list_inner3_outer_img_div_list_h1">
            {item.name}
          </h1>
          <div className="case_list_inner3_outer_img_div_list_read">
            <img
              alt="read"
              src={read_img}
              className="case_list_inner3_outer_img_div_list_read_img"
              loading="lazy"
            />
            <p className="case_list_inner3_outer_img_div_list_read_p">
              {item.time}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
