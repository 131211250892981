import React from "react";
import "../../styles/ContactUs/ContactUs.css";
import contact_us_img from "../../Images/contact_us/mobile.webp";
import whatsapp_img from "../../Images/contact_us/whatsapp.webp";
import mail_us_img from "../../Images/contact_us/mail.webp";
import map_img from "../../Images/contact_us/map.webp";
import map_img1 from "../../Images/contact_us/map1.webp";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setFName,
  setLName,
  setCEmail,
  setRequirement,
} from "../../Redux/Cart/CartStore";
import { contactUs } from "../Functions/contactUs";

export default function ContactUs() {
  const device = useMediaQuery("(min-width:768px)");
  const navigate = useNavigate();

  const fName = useSelector((state) => state.cart.fName);
  const lName = useSelector((state) => state.cart.lName);
  const cEmail = useSelector((state) => state.cart.cEmail);
  const requirement = useSelector((state) => state.cart.requirement);

  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log("called");
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, []);

  async function sendItems() {
    if (!fName || fName.length == 0) {
      alert("First Name required");
      return;
    } else if (!lName || lName.length == 0) {
      alert("Last Name required");
      return;
    } else if (!cEmail || cEmail.length == 0) {
      alert("Email required");
      return;
    } else if (!requirement || requirement.length == 0) {
      alert("Write your requirement");
      return;
    }

    const res = await contactUs(fName, lName, cEmail, requirement);

    if (res) {
      navigate("/thank-you");
    }
  }

  return (
    <div className="contact_we_supream_outer">
      <div className="contact_we_super_outer">
        <div className="contact_we_outer">
          <div className="contact_us_get_in_touch">
            <h1 className="contact_us_get_in_touch_h1">Get in touch</h1>
            <p className="contact_us_get_in_touch_p">Lets grow together</p>
          </div>
          <div className="contact_we_outer_2_form">
            <div className="contact_we_outer_2_form_form">
              <input
                className="contact_we_outer_2_form_form_input_style shadow-sm"
                placeholder=" First Name"
                value={fName}
                onChange={(e) => {
                  dispatch(setFName(e.target.value));
                }}
              />
              <input
                className="contact_we_outer_2_form_form_input_style shadow-sm"
                placeholder=" Last Name"
                value={lName}
                onChange={(e) => {
                  dispatch(setLName(e.target.value));
                }}
              />
              <input
                className="contact_we_outer_2_form_form_input_style shadow-sm"
                placeholder=" Email ID"
                value={cEmail}
                onChange={(e) => {
                  dispatch(setCEmail(e.target.value));
                }}
              />
              <textarea
                className="contact_we_outer_2_form_form_input_style_2 shadow-sm"
                placeholder=" Tell us your requirement"
                value={requirement}
                onChange={(e) => {
                  dispatch(setRequirement(e.target.value));
                }}
              ></textarea>
              <button className="contact_us_button_class" onClick={sendItems}>
                Send
              </button>
            </div>
            <div className="contact_we_outer_2_form_middle"></div>
            <div className="contact_we_outer_2_form_chat_map">
              <div className="contact_we_outer_2_form_chat_map_1">
                {device ? (
                  <a
                    href="tel:9900601788"
                    target="_blank"
                    className="contact_we_outer_2_form_chat_map_1_inner_list"
                    style={{ backgroundImage: `url(${contact_us_img})` }}
                  ></a>
                ) : (
                  <a href="tel:9900601788" target="_blank">
                    <img
                      src={contact_us_img}
                      className="contact_we_outer_2_form_chat_map_1_inner_list"
                      loading="lazy"
                    />
                  </a>
                )}
                {device ? (
                  <a
                    href="https://wa.me/9620777344"
                    target="_blank"
                    className="contact_we_outer_2_form_chat_map_1_inner_list"
                    style={{ backgroundImage: `url(${whatsapp_img})` }}
                  ></a>
                ) : (
                  <a href="https://wa.me/9620777344" target="_blank">
                    <img
                      src={whatsapp_img}
                      className="contact_we_outer_2_form_chat_map_1_inner_list"
                      loading="lazy"
                    />
                  </a>
                )}
                {device ? (
                  <a
                    href="mailto:Santosh@amsantechnology.com"
                    target="_blank"
                    className="contact_we_outer_2_form_chat_map_1_inner_list"
                    style={{ backgroundImage: `url(${mail_us_img})` }}
                  ></a>
                ) : (
                  <a href="mailto:Santosh@amsantechnology.com" target="_blank">
                    <img
                      src={mail_us_img}
                      className="contact_we_outer_2_form_chat_map_1_inner_list"
                      loading="lazy"
                    />
                  </a>
                )}
              </div>
              <a
                href="https://www.google.com/maps/dir//amsan+technology+reviews/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bae15d843c61189:0x62109fc9ab66186a?sa=X&ved=2ahUKEwjP1e-H3OaCAxVmS2wGHcKlBmQQ9Rd6BAhQEAA&sqi=2"
                target="_blank"
              >
                <div
                  className="contact_we_outer_2_form_chat_map_2"
                  style={{
                    backgroundImage: `url(${device ? map_img : map_img1})`,
                  }}
                ></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
