import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { supabase } from "../../Supabase";
import {
  setNameA,
  setCityA,
  setEmailA,
  setPhoneA,
} from "../../Redux/Cart/CartStore";
import { useDispatch, useSelector } from "react-redux";
import { pushDemoRequest } from "../Functions/demoCustomers";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../Firebase";
import { toast, Toaster } from "react-hot-toast";
import { fetchIpAddress } from "../Functions/fetchIPAddress";

export default function FormUI(props) {
  const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();

  const name = useSelector((state) => state.cart.name_a);
  const phone = useSelector((state) => state.cart.phone_a);
  const email = useSelector((state) => state.cart.email_a);
  const city = useSelector((state) => state.cart.city_a);
  const [loading, setLoading] = React.useState(false);

  const [msg, setMSG] = React.useState("");
  const [msgStatus, setMSGStatus] = React.useState(false);
  const navigate = useNavigate();

  async function pushDemoCustomers() {
    if (name.length == 0) {
      setMSG("Name is required");
      setMSGStatus(true);
      return;
    }
    if (phone.length == 0) {
      setMSG("Phone number is required");
      setMSGStatus(true);
      return;
    }
    if (city.length == 0) {
      setMSG("City name is required");
      setMSGStatus(true);
      return;
    }

    if (phone.length != 10) {
      setMSG("Phone number should be valid");
      setMSGStatus(true);
      return;
    }

    setLoading(true);
    // onSignup();
    const res = await pushDemoRequest(name, phone, email, city);

    fetchIpAddress({
      name: name,
      phone: phone,
      city: city,
      email: email,
    });

    console.log("Res: ", res);

    setLoading(false);
    if (res) {
      props.setFormOpen(false);
      // setOpen(false);
      // dispatch(setNameA(""));
      // dispatch(setEmailA(""));
      // dispatch(setCityA(""));
      // dispatch(setPhoneA("+91 " + phone));
      navigate("/thank-you");
      // navigate("/verify");
    }
  }

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();
    setOpen(false);

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+91 " + phone;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;

        //  setShowOTP(true);
        toast.success("OTP sent successfully!");
        props.setFormOpen(false);
        setOpen(false);
        dispatch(setPhoneA("+91 " + phone));
        navigate("/verify");
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // setLoading(false);
      });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        // initialFocus={cancelButtonRef}
        onClose={() => {
          props.setFormOpen(false);
          setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                <div className="home_form shadow-md" style={{ width: "100%" }}>
                  <div>
                    <h1 className="h2">Sign up for free modules</h1>
                    <div className="h2-bottom"></div>
                    <div className="input-form">
                      <input
                        type="text"
                        className="input_text shadow-sm"
                        placeholder="Name*"
                        value={name}
                        onChange={(e) => {
                          dispatch(setNameA(e.target.value));
                        }}
                      />
                      <input
                        type="number"
                        className="input_text shadow-sm"
                        placeholder="+91  |  Mobile Number*"
                        value={phone}
                        onChange={(e) => {
                          dispatch(setPhoneA(e.target.value));
                        }}
                      />
                      <div class="input-container">
                        {email?.length == 0 ? (
                          <div class="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z"
                              />
                            </svg>
                          </div>
                        ) : null}
                        <input
                          type="email"
                          className="input_text shadow-sm"
                          placeholder="    Email"
                          style={{ paddingLeft: "30px" }}
                          value={email}
                          onChange={(e) => {
                            dispatch(setEmailA(e.target.value));
                          }}
                        />
                      </div>

                      <div class="input-container">
                        {city?.length == 0 ? (
                          <div class="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                              />
                            </svg>
                          </div>
                        ) : null}
                        <input
                          type="text"
                          className="input_text shadow-sm"
                          placeholder="    City*"
                          style={{ paddingLeft: "30px" }}
                          value={city}
                          onChange={(e) => {
                            dispatch(setCityA(e.target.value));
                          }}
                        />
                      </div>
                      {msgStatus && (
                        <div
                          class="p-4 mb-0 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 mt-4"
                          role="alert"
                        >
                          <span class="font-medium">{msg} !</span>
                        </div>
                      )}
                      <div className="button_div" onClick={pushDemoCustomers}>
                        <button className="button_class">
                          {loading ? "Wait..." : "Get free modules"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
