import React from "react";
import "../../styles/CaseStudies/CaseStudies.css";
import ListUI from "./List";
import AllUI from "./All";

export default function CaseStudiesUI() {
  React.useEffect(() => {
    console.log("called");
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="case_outer_div">
      <ListUI />
      <AllUI />
    </div>
  );
}
