import supabase from "../../Supabase";
import emailjs from "@emailjs/browser";
import axios from "axios";

export const pushDemoRequest = async (name, phone, email, city) => {
  try {
    axios
      .post(process.env.REACT_APP_BACKEND_API + "post_lead_upsert", {
        name: name,
        phone: phone,
        email: email,
        city: city,
        verified: "false",
      })
      .then((u) => {
        console.log(u);
      })
      .catch((er) => {
        console.log(er);
      });
  } catch (err) {
    console.log(err);
  }

  try {
    const { data: newRecord, error } = await supabase
      .from("demo_requests")
      // .from("demo_requests_test")
      .insert([
        {
          name: name,
          mobile: phone,
          email: email,
          city: city,
        },
      ]);

    try {
      if (window.Email) {
        window.Email.send({
          SecureToken: process.env.REACT_APP_SECURE_TOKEN,
          To: "growth@amsantechnology.com",
          From: "growth@amsantechnology.com",
          Subject: "New Contact from " + city,
          Body: `<p>Hello Santosh,</p>
<p>You got a new contact from ${city}:</p>
<p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">
Name : ${name}<br>
Email : ${email}<br>
Phone : ${phone}<br>
City : ${city}<br>
</p>
<p>
  Best wishes,<br>Team Amsan
</p>`,
        }).then((message) => {
          console.log("Succedss");
        });
      }
    } catch (err) {
      console.log(err);
    }

    // try {
    //   let userData = {
    //     name: name,
    //     mobile: phone,
    //     email: email,
    //     city: city,
    //   };

    //   const dummyForm = document.createElement("form");

    //   Object.entries(userData).forEach(([key, value]) => {
    //     const input = document.createElement("input");
    //     input.type = "hidden";
    //     input.name = key;
    //     input.value = value;
    //     dummyForm.appendChild(input);
    //   });

    //   document.body.appendChild(dummyForm);

    //   const formSelector = "form";

    //   emailjs
    //     .sendForm(
    //       process.env.REACT_APP_SERVICE_KEY,
    //       process.env.REACT_APP_TEMPLATE_KEY,
    //       formSelector,
    //       process.env.REACT_APP_PUBLIC_KEY
    //     )
    //     .then(
    //       (result) => {
    //         console.log(result.text);
    //       },
    //       (error) => {
    //         console.log(error.text);
    //       }
    //     )
    //     .catch((err) => {
    //       console.log("no");
    //       console.log(err);
    //     });
    // } catch (err) {
    //   console.log(err);
    // }

    if (error) {
      console.error("Error pushing data to Supabase:", error);
      return false;
    }

    console.log("Data pushed to Supabase:", newRecord);
    return true;
  } catch (error) {
    console.error("Error:", error.message);
    return false;
  }
};
