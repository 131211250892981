import React from "react";
import Modal from "react-modal";
import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";

const ShareModal = ({ isOpen, closeModal, shareUrl }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Share Modal"
    >
      <h2>Share Options</h2>
      <FacebookShareButton url={shareUrl}>
        Share on Facebook
      </FacebookShareButton>
      <WhatsappShareButton url={shareUrl}>
        Share on WhatsApp
      </WhatsappShareButton>
      <LinkedinShareButton url={shareUrl}>
        Share on LinkedIn
      </LinkedinShareButton>
      <button onClick={closeModal}>Close</button>
    </Modal>
  );
};

export default ShareModal;
